/*
colors
#5e525e bar
#f3e9b8 //Yellow backGround
*/
*{
    margin:0;
    padding: 0;
    box-sizing: border-box;
}

:root{
    --bar:#5e525e88;
}

.custom-video{
    width:100%;
    height:auto;
    position: relative;
    overflow: hidden;
}

.custom-video video{
    width:100%;
    height:100%;

}

.custom-video .container-toolbar{
    position: absolute;
    bottom: 0;
    left:50%;
    transform: translateX(-50%);
    margin-inline: auto;

    width:100%;
    height: auto;

}
.toolbar{
    background: #282c3488;

}

.custom-video:is(:fullscreen) .toolbar {
    padding: 10px 0px;
}