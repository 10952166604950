
:root{
    --color:#fedc23;

    --size:0%;
    --volumeSize:0%;
}
.bar{
    -webkit-appearance: none;
    background-color: #eee4;
    background-image: linear-gradient(var(--color) ,var(--color));
    background-size: var(--size) 100% ;
    background-repeat: no-repeat;
    padding: 0;
    /*pointer-events: ;*/
    height: 4px;
    z-index: 4;
    position: relative;

}


.bar::-webkit-slider-thumb{
appearance: none;
    position: absolute;
    left:var(--size);
    top:50%;
    transform: translate(-50%,-50%);
    background-image: linear-gradient(#fff ,#fff);
    height: 10px;
    width:10px;
    border-radius: 50%;
pointer-events: none;

}

.barVolume{
    -webkit-appearance: none;
    background-color: #eee4;
    background-image: linear-gradient(var(--color) ,var(--color));
    background-size: var(--volumeSize) 100% ;
    background-repeat: no-repeat;
    padding: 0;
    height: 4px;
    z-index: 4;
    position: relative;
}


.barVolume::-webkit-slider-thumb{
    appearance: none;
    position: absolute;
    left:var(--volumeSize);
    top:50%;
    transform: translate(-50%,-50%);
    background-image: linear-gradient(#fff ,#fff);
    height: 10px;
    width:10px;
    border-radius: 50%;
    pointer-events: none;

}

.bar:hover,.barVolume:hover{
    height:6px;
}


.hide::-webkit-slider-thumb{
    visibility: hidden !important;
}




